/* You can add global styles to this file, and also import other style files */
.regular-header {
    background-color: #2E7D32 !important;
}

.regular-content {
    background-color: #43A047 !important;
}

.premium-header {
    background-color: #C62828 !important;
}

.premium-content {
    background-color: #E53935 !important;
}

.diesel-header {
    background-color: #424242 !important;
}

.diesel-content {
    background-color: #757575 !important;
}

/* -------------------------------------------------------------------------- */
/*                              ANCHOR BACKGROUND                             */
/* -------------------------------------------------------------------------- */

.bg-center{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-secondary {
    background-color: #F2F2F7 !important;
}

.bg-transparent-dark{
    background-color: rgba(0, 0, 0, 0.4) !important
}

.bg-grey-darken-4{
    background-color: #212121 !important;
}

.bg-grey-lighten-3{
    background-color: #eeeeee !important;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR SHIMMER                               */
/* -------------------------------------------------------------------------- */
.shimmer {
    animation : animate 2.5s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
}
 
@keyframes animate {
   0% {
    background-position: -1000px 0;
   }
   100% {
     background-position: 1000px 0;
   }
}

/* -------------------------------------------------------------------------- */
/*                                 ANCHOR TEXT                                */
/* -------------------------------------------------------------------------- */
.text-justify {
    text-align: justify !important
}